import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormAmountInput from "../../Components/modules/simulations/SimulatorForm/FormAmountInput";
import FormSelect from "../../Components/ui/Form/FormSelect";
import styles from "./SimulatorFormScreen.module.scss";
import {
  calculateLoan,
  getBirthday,
  getMonthSimulator,
  getPossibleDurations,
  numberFormat,
} from "../../helpers/simulator";
import { formatNumber } from "../../helpers/validation";
import GenerateFileInput from "../../Components/modules/simulations/SimulatorForm/GenerateFileInput";
import FormSelectMini from "../../Components/ui/Form/FormSelectMini";
import Modal from "../../Components/ui/Modal/Modal";
import Button from "../../Components/ui/Button/Button";

const SimulatorFormScreen = ({ equipment, equipmentsLoans, dataSimulator, showResults }) => {
  const currentDate = new Date();
  const minAge = 18;
  const maxAge = 64;
  const minYear = new Date(`${currentDate.getFullYear() - 120}-01-01`);
  const regex = new RegExp(`^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])([0-9]{4})$`);
  const errorRegex = "Valeur invalide";
  const errorAge = "L'age minimum est de 18 ans";
  const nullValueError = "Champ obligatoire";
  const none = "Aucune";
  const D = "D";
  const yes = "Oui";
  const no = "Non";
  const without = "Sans";
  const withStr = "Avec";
  const [ageInsurance, setAgeInsurance] = useState(0);
  const [ageCoInsurance, setAgeCoInsurance] = useState(0);
  const [equipmentLoans, setEquipmentLoans] = useState([]);
  const [modalities, setModalities] = useState([]);
  const [loanModality, setLoanModality] = useState(null);
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [isPostpone, setIsPostpone] = useState(false);
  const [activityBorrower, setActivityBorrower] = useState(true);
  const [activityCoBorrower, setActivityCoBorrower] = useState(true);
  const [insurance, setInsurance] = useState("DIM");
  const [coInsurance, setCoInsurance] = useState(none);
  const [loanAmount, setLoanAmount] = useState("");
  const [errorBirthdayCoInsurance, setErrorBirthdayCoInsurance] = useState("");
  const [errorBirthdayInsurance, setErrorBirthdayInsurance] = useState("");
  const [birthdayCoInsurance, setBirthdayCoInsurance] = useState("");
  const [birthdayInsurance, setBirthdayInsurance] = useState("");
  const [insurances, setInsurances] = useState([""]);
  const [coInsurances, setCoInsurances] = useState([""]);
  const postponeValues = [without, withStr];
  const activityValues = [yes, no];
  const [showPopup, setShowPopup] = useState(false);
  const [popUpAlreadyOpened, setPopUpAlreadyOpened] = useState(false);
  const [borrowerIsBetween18And64, setBorrowerIsBetween18And64] = useState(false);
  const [coBorrowerIsBetween18And64, setCoBorrowerIsBetween18And64] = useState(false);

  useEffect(() => {
    setLoanAmount("");
    setBirthdayInsurance("");
    setBirthdayCoInsurance("");
    setInsurance("DIM");
    setCoInsurance(none);
    setIsPostpone(false);
  }, [equipment]);

  useEffect(() => {
    const loans = equipmentsLoans.filter(
      (equipmentLoansLink) => equipmentLoansLink.typeEquipmentId === equipment.id
    );
    if (loans) {
      setEquipmentLoans(loans);
      const availableModalities = loans
        .map((loan) => loan.scale)
        .filter((scale, index, result) => result.indexOf(scale) === index);
      setModalities([...availableModalities]);
    }
    // eslint-disable-next-line
  }, [equipment, equipmentsLoans]);

  useEffect(() => {
    if (modalities.length > 0) {
      setLoanModality(modalities[0]);
    }
  }, [modalities, equipmentLoans]);

  useEffect(() => {
    if (loanModality) {
      const amountSteps = getLoan()?.loanAmountStep.filter(
        (amount) => amount.scale === loanModality
      );
      let minAmountTemp = amountSteps[0].minAmount;
      let maxAmountTemp = amountSteps[0].maxAmount;

      amountSteps.forEach((amount) => {
        if (amount.minAmount <= minAmountTemp) {
          minAmountTemp = amount.minAmount;
        }
        if (amount.maxAmount >= maxAmountTemp) {
          maxAmountTemp = amount.maxAmount;
        }
      });
      setMinAmount(minAmountTemp);
      setMaxAmount(maxAmountTemp);
    }
    // eslint-disable-next-line
  }, [loanModality, isPostpone, equipmentLoans]);

  useEffect(() => {
    if (ageInsurance > maxAge || !activityBorrower) {
      setInsurance(insurance !== D ? D : insurance);
      setInsurances([D]);
    } else {
      setInsurances([D, "DIM", "DIMC"]);
    }
    if (ageCoInsurance > maxAge || !activityCoBorrower) {
      setCoInsurance(coInsurance !== D && coInsurance !== none ? D : coInsurance);
      setCoInsurances([D, none]);
    } else {
      setCoInsurances([D, "DIM", "DIMC", none]);
    }
  }, [ageInsurance, ageCoInsurance, activityBorrower, activityCoBorrower, insurance, coInsurance]);

  const getLoan = () =>
    equipmentLoans.find((loan) => loan.scale === loanModality && loan.postpone === isPostpone);

  const isPostponeAvailable = (equipmentloans) =>
    equipmentloans
      .map((loan) => loan.postpone)
      .filter((postpone, index, result) => {
        return result.indexOf(postpone) === index && postpone === true;
      });

  const isAmountValid = (amount) => {
    const amountToValidate = amount ? parseFloat(amount.replace(/\s/g, "")) : "";
    return amountToValidate && amountToValidate <= maxAmount && amountToValidate >= minAmount;
  };

  const textAmountStep = () => {
    let textStyle = [styles.AmountLimit];
    if (loanAmount && !isAmountValid(loanAmount)) {
      textStyle.push(styles.AmountLimitError);
    }

    return (
      maxAmount &&
      minAmount && (
        <p className={textStyle.join(" ")}>
          {`Le montant doit être compris entre ${formatNumber(minAmount)} et ${formatNumber(
            maxAmount
          )}`}
        </p>
      )
    );
  };

  useEffect(() => {
    if (coInsurance === "D" && insurance === "D") setPopUpAlreadyOpened(false);
  }, [insurance, coInsurance]);

  useEffect(() => {
    if (borrowerIsBetween18And64 || coBorrowerIsBetween18And64) {
      if (
        loanAmount &&
        (insurance === "D" || coInsurance === "D") &&
        activityBorrower &&
        !popUpAlreadyOpened
      ) {
        setShowPopup(true);
        setPopUpAlreadyOpened(true);
      }
    }
  }, [
    activityBorrower,
    loanAmount,
    insurance,
    coInsurance,
    popUpAlreadyOpened,
    borrowerIsBetween18And64,
    coBorrowerIsBetween18And64,
  ]);

  useEffect(() => {
    if (borrowerIsBetween18And64 || coBorrowerIsBetween18And64) {
      if (
        loanAmount &&
        (insurance === "D" || coInsurance === "D") &&
        (activityBorrower || activityCoBorrower) &&
        !popUpAlreadyOpened
      ) {
        setShowPopup(true);
        setPopUpAlreadyOpened(true);
      }
    }
  }, [
    activityBorrower,
    activityCoBorrower,
    loanAmount,
    insurance,
    coInsurance,
    popUpAlreadyOpened,
    borrowerIsBetween18And64,
    coBorrowerIsBetween18And64,
  ]);

  const checkDate = (date, isCoInsurance = false) => {
    if (isCoInsurance && coInsurance === none && errorBirthdayCoInsurance) {
      setErrorBirthdayCoInsurance("");
    }
    if (date === "") {
      // Check if date is not null
      if (errorBirthdayInsurance !== nullValueError && !isCoInsurance) {
        setErrorBirthdayInsurance(nullValueError);
      }
      if (errorBirthdayCoInsurance !== nullValueError && coInsurance !== none && isCoInsurance) {
        setErrorBirthdayCoInsurance(nullValueError);
      }
    } else if (!regex.test(date) && date !== "") {
      // Check if date is valid
      if (errorBirthdayInsurance !== errorRegex && !isCoInsurance) {
        setErrorBirthdayInsurance(errorRegex);
      }
      if (errorBirthdayCoInsurance !== errorRegex && coInsurance !== none && isCoInsurance) {
        setErrorBirthdayCoInsurance(errorRegex);
      }
    } else {
      const birthday = getBirthday(date);

      let age = currentDate.getFullYear() - birthday.getFullYear();

      if (
        currentDate.getMonth() < birthday.getMonth() ||
        (currentDate.getMonth() === birthday.getMonth() &&
          currentDate.getDate() < birthday.getDate())
      ) {
        age--;
      }

      // Save age of borrower and co-borrower
      if (!isCoInsurance && age !== ageInsurance) {
        setAgeInsurance(age);
      } else if (isCoInsurance && age !== ageCoInsurance) {
        setAgeCoInsurance(age);
      }
      if (age >= minAge && age <= maxAge) {
        // If borrower and co-borrower have 18-64 years
        if (errorBirthdayInsurance && !isCoInsurance) {
          setBorrowerIsBetween18And64(true);
          setErrorBirthdayInsurance("");
        }
        if (errorBirthdayCoInsurance && coInsurance !== none && isCoInsurance) {
          setCoBorrowerIsBetween18And64(true);
          setErrorBirthdayCoInsurance("");
        }
      } else if (date !== "") {
        // If borrower and co-borrower have more 64 year and less 120 year
        if (!isCoInsurance && insurance !== D && age >= minAge && birthday >= minYear) {
          setInsurance(D);
        }
        if (
          coInsurance !== D &&
          isCoInsurance &&
          coInsurance !== none &&
          age >= minAge &&
          birthday >= minYear
        ) {
          setCoInsurance(D);
        }

        // If borrower and co-borrower have < min age
        if (
          errorBirthdayInsurance !== errorAge &&
          !isCoInsurance &&
          age < minAge &&
          birthday <= currentDate
        ) {
          setErrorBirthdayInsurance(errorAge);
        }
        if (
          errorBirthdayCoInsurance !== errorAge &&
          isCoInsurance &&
          coInsurance !== none &&
          age < minAge &&
          birthday <= currentDate
        ) {
          setErrorBirthdayCoInsurance(errorAge);
        }

        // If borrower and co-borrower have more 120 year or year is less than the current year
        if (
          errorBirthdayInsurance !== errorRegex &&
          !isCoInsurance &&
          (birthday > currentDate || birthday < minYear)
        ) {
          setErrorBirthdayInsurance(errorRegex);
        }
        if (
          errorBirthdayCoInsurance !== errorRegex &&
          isCoInsurance &&
          (birthday > currentDate || birthday < minYear)
        ) {
          setErrorBirthdayCoInsurance(errorRegex);
        }

        // Reset error
        if (
          errorBirthdayInsurance &&
          insurance === D &&
          !isCoInsurance &&
          age > minAge &&
          birthday >= minYear
        ) {
          setErrorBirthdayInsurance("");
          setBorrowerIsBetween18And64(false);
        }
        if (
          errorBirthdayCoInsurance &&
          coInsurance === D &&
          isCoInsurance &&
          age > minAge &&
          birthday >= minYear
        ) {
          setErrorBirthdayCoInsurance("");
          setCoBorrowerIsBetween18And64(false);
        }
      }
    }
  };

  const renderMonthlyArray = () => {
    checkDate(unDisplayDate(birthdayInsurance));
    checkDate(unDisplayDate(birthdayCoInsurance), true);

    if (!isAmountValid(loanAmount) || errorBirthdayInsurance || errorBirthdayCoInsurance) {
      return;
    }

    const possibilities = calculatePossibleDuration();

    if (possibilities.length === 0) {
      return <div className={styles.Error}>Pas de durées proposables.</div>;
    }

    return (
      <div className={styles.FormMonthly}>
        <table className={[styles.Tab, "striped"].join(" ")}>
          <thead>
            <tr>
              <th
                rowSpan={2}
                className={styles.MonthlyValueTitleBold}
                style={{ verticalAlign: "baseline" }}
              >
                Durée
              </th>
              <th colSpan={2} className={styles.MonthlyValueTitleBold}>
                Mensualités
              </th>
            </tr>
            <tr>
              <th className={styles.MonthlyValueTitle}>Hors assurance</th>
              <th className={styles.MonthlyValueTitleBold}>Avec assurance</th>
            </tr>
          </thead>
          <tbody>{renderPossiblesDurations(possibilities)}</tbody>
        </table>
      </div>
    );
  };

  const calculatePossibleDuration = () => {
    const validateAmount = loanAmount ? parseInt(loanAmount.replace(/\s/g, "")) : "";
    return getPossibleDurations(
      validateAmount,
      findEquipmentLoan(),
      dataSimulator,
      getMonthSimulator(unDisplayDate(birthdayInsurance))
    );
  };

  const renderPossiblesDurations = (possibleDurations) => {
    const validateAmount = loanAmount ? parseInt(loanAmount.replace(/\s/g, "")) : "";
    const formData = {
      duration: 0,
      amount: validateAmount,
      insuranceBorrower: insurance,
      insuranceCoborrower: coInsurance,
      equipment: equipment,
    };

    return possibleDurations.map((possibleDuration) => {
      formData.duration = possibleDuration;
      const results = calculateLoan(
        formData,
        findEquipmentLoan(),
        dataSimulator,
        ageInsurance,
        ageCoInsurance
      );
      results["birthdayBorrower"] = unDisplayDate(birthdayInsurance) ?? null;
      results["birthdayCoBorrower"] = unDisplayDate(birthdayCoInsurance) ?? null;

      return (
        <tr onClick={() => showResults(results)} key={possibleDuration}>
          <td className={styles.MonthlyKey}>{possibleDuration} mois</td>
          <td className={styles.MonthlyValue}>{numberFormat(results.monthly1)} €</td>
          <td className={styles.MonthlyValueBold}>
            {numberFormat(results.monthly1InsLin)} €<span className={styles.MonthlyArrow} />
          </td>
        </tr>
      );
    });
  };

  const findEquipmentLoan = () => {
    return equipmentLoans.find((equipmentLoan) => {
      return equipmentLoan.scale === loanModality && equipmentLoan.postpone === isPostpone;
    });
  };
  const unDisplayDate = (value) => {
    return value.replace(/-/g, "");
  };

  const displayDate = (value) => {
    if (value.length === 8 && value.indexOf("-") <= 0) {
      const day = value.substring(0, 2);
      const month = value.substring(2, 4);
      const year = value.substring(4);
      value = day + "-" + month + "-" + year;
    }

    return value;
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>{equipment.equipmentName}</div>
      <div className={styles.FormWrapper}>
        <div className={styles.FormFields}>
          <FormAmountInput
            label="Montant du prêt"
            type="text"
            placeholder="Montant du prêt"
            value={loanAmount}
            onChange={(amount) => setLoanAmount(formatNumber(amount))}
          />
          {textAmountStep()}
          {isPostponeAvailable(equipmentLoans).length > 0 && (
            <FormSelect
              title="Report à 180 jours ?"
              elements={postponeValues}
              onClick={(value) => setIsPostpone(value === withStr)}
              selectedElement={isPostpone ? withStr : without}
            />
          )}
          {modalities.length > 1 && (
            <FormSelect
              title="Modalités"
              elements={modalities}
              onClick={(value) => setLoanModality(value)}
              selectedElement={loanModality}
            />
          )}
          <div className={styles.wrapperInput}>
            <GenerateFileInput
              label="Date de naissance emprunteur"
              errorMessage={errorBirthdayInsurance}
              onChange={(value) => {
                setBirthdayInsurance(value);
              }}
              value={displayDate(birthdayInsurance)}
              defaultValue={displayDate(birthdayInsurance)}
              placeholder="Format JJMMAAAA"
            />
            <FormSelectMini
              title="En activité ?"
              elements={activityValues}
              onClick={(value) => setActivityBorrower(value === yes)}
              selectedElement={activityBorrower ? yes : no}
            />
          </div>
          <FormSelect
            title="Assurance emprunteur"
            elements={insurances}
            onClick={(value) => setInsurance(value)}
            selectedElement={insurance}
          />
          {coInsurance !== none && (
            <div className={styles.wrapperInput}>
              <GenerateFileInput
                label="Date de naissance co-emprunteur"
                errorMessage={errorBirthdayCoInsurance}
                onChange={(value) => setBirthdayCoInsurance(value)}
                value={displayDate(birthdayCoInsurance)}
                defaultValue={displayDate(birthdayCoInsurance)}
                placeholder="Format JJMMAAAA"
              />
              <FormSelectMini
                title="En activité ?"
                elements={activityValues}
                onClick={(value) => setActivityCoBorrower(value === yes)}
                selectedElement={activityCoBorrower ? yes : no}
              />
            </div>
          )}
          <FormSelect
            title="Assurance co-emprunteur"
            elements={coInsurances}
            onClick={(value) => setCoInsurance(value)}
            selectedElement={coInsurance}
          />
          <div className={styles.Info}>Simulation non contractuelle</div>
        </div>
        {renderMonthlyArray()}
      </div>
      {showPopup && (
        <Modal
          show={showPopup}
          onHide={() => setShowPopup(false)}
          title="Information"
          footer={<Button onClick={() => setShowPopup(false)}> j'ai compris </Button>}
        >
          <p>
            Si vous sélectionnez une durée de remboursement supérieure à 84 mois, nous vous invitons
            à consulter les différentes couvertures d’assurance pour proposer la meilleure
            tarification
          </p>
        </Modal>
      )}
    </div>
  );
};

SimulatorFormScreen.propTypes = {
  equipment: PropTypes.object.isRequired,
  equipmentsLoans: PropTypes.array.isRequired,
  dataSimulator: PropTypes.shape().isRequired,
  showResults: PropTypes.func.isRequired,
};

export default SimulatorFormScreen;
